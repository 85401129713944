<template>
  <!-- <div
    
  >
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <div class="swiper-pagination swiper-pagination-bullets"></div> 
  </div> -->
  <!-- <swiper
    ref="mySwiper007"
    v-if="initStatus"
    v-bind="$attrs"
    v-on="$listeners"
    :class="cusClass + ' swiperWrap swiperBox ' + swpName"
    :options="swiperOption"
  >
    <div class="swiper-wrapper">
      <slot></slot>
    </div>

  </swiper> -->
  <div :id="sid" class="swiper-container">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/dist/idangerous.swiper.min.js";
import "swiper/dist/idangerous.swiper.css";
export default {
  props: {
    list: {
      //banner数组
      type: Array,
      default: function() {
        return [];
      },
    },
    cusClass: {
      //自定义类名
      type: String,
      default: "",
    },
    sid: {
      type: String,
      default: "",
    },
    loop: {
      //是否循环
      type: Boolean,
      default: true,
    },
    delay: {
      //间隔时间
      type: Number,
      default: 5000,
    },
    spaceBetween: {
      //两个恶轮播的间隔
      type: Number,
      default: 0,
    },
    slidesPerView: {
      //一页显示几个
      type: Number,
      default: 1,
    },
    direction: {
      //显示方式
      type: String,
      default: "horizontal",
    },
    autoplay: {
      //是否轮播
      type: Boolean,
      default: false,
    },
    paginationType: {
      //导航点类型
      // 'bullets'  圆点（默认）
      // 'fraction'  分式
      // 'progressbar'  进度条
      // 'custom' 自定义
      type: String,
      default: "",
    },
  },
  data() {
    let _that = this;

    return {
      swiperSl: "",
      initStatus: true, //初始化状态
      partnerSwiperOption: {
        watchActiveIndex: true,
        lazyLoading: true, // 图片懒加载
        lazyLoadingInPrevNext: true,
        preventLinksPropagation: false,
        preventClicks: false,
        direction: this.direction,
        loop: this.loop,
        speed:600,
        updateOnImagesReady: true, // 所有内部图像（<img>标签）装入后重新初始化Swipe
        calculateHeight: true,
        slidesPerView: this.slidesPerView, //一页显示几个
        spaceBetween: this.spaceBetween, //间隔
        resizeReInit : true,//设置为true则windows改变尺寸时swiper会重新初始化
        autoplayDisableOnInteraction: false,
        autoplay: this.autoplay ? this.delay : null,
        onSlideChangeStart: function(swiper) {
          _that.$emit("onSlideChangeEnd", swiper);
        },
      },
    };
  },
  mounted() {
    // if(this.sid!="indexBannerSwiper"){
    this.initSwiper();
    // }
  },
  methods: {
    initSwiper() {
      this.swiperSl = new Swiper("#" + this.sid, this.partnerSwiperOption);
    },
  },
};
</script>
<style lang="scss" scoped></style>
